@import "@alltrails/shared/styles/landsEnd.scss";
.title {
  @include heading-400;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: var(--space-450);

  @include page-width-lg {
    @include heading-600;
  }
}
